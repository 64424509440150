/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import AccessTime from "@material-ui/icons/AccessTime";
import SignalWifi0BarIcon from '@material-ui/icons/SignalWifi0Bar';
import SignalWifi1BarIcon from '@material-ui/icons/SignalWifi1Bar';
import SignalWifi2BarIcon from '@material-ui/icons/SignalWifi2Bar';
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
//core components
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import axios from "axios";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import netlifyIdentity from 'netlify-identity-widget';

class Dashboard2 extends React.Component {
    state = {
        scaleId: "6612472874368737031",
        userEmail: "matejje@hacker.eu", //netlifyIdentity.currentUser().email,

        value: 0,
        isStarted: false,
        noConnection: "none",
        setBackendAddress: "none",
        newKeg: "none",
        backendAddress: process.env.REACT_APP_COMPUTER_IP_ADDRESS,
        iframeSrc:
            '<iframe src="https://' +
            process.env.REACT_APP_COMPUTER_IP_ADDRESS +
            ":3042/d-solo/" +
            process.env.REACT_APP_GRAFANA_ID +
            '/pivalert?orgId=2&refresh=5s&panelId=2&fullscreen" width="100%" height="400" frameborder="0"></iframe>',
        errorMessage: "",
        beerName: "",
        tapDate: null,
        remainingBeers: "",
        temperature: "20",
        endOfBeer: "42:42",
        beerUpdatedAt: null,
        temperatureUpdatedAt: null,
        beerDoneAtUpdatedAt: null,
        rssi: "-42",
        maxBeers: "",
        password: ""
    };

    checkIfKegExists() {
    }

    changedIPAddress = event => {
        if (event != null && event.target.value != null) {
            (async () => {
                await this.setState({backendAddress: event.target.value.trim()});
                console.log("Setting backend address to: " + this.state.backendAddress);
                this.componentDidMount();
            })();
        }
    };

    componentDidMount() {
        let thisCopy = this;
        axios
            .get(
                "https://" +
                thisCopy.state.backendAddress +
                ":8097/kegs/unfinishedexists?useremail=" + thisCopy.state.userEmail
            )
            .then(response => {
                thisCopy.setState({noConnection: "none"});
                thisCopy.setState({setBackendAddress: "none"});
                if (response.data.status === "OK") {
                    thisCopy.setState({maxBeers: response.data.response.volume * 2});
                    thisCopy.setState({
                        remainingBeers: response.data.response.remainingBeers
                    });
                    thisCopy.setState({
                        temperature: response.data.response.temperature
                    });
                    thisCopy.setState({
                        endOfBeer: response.data.response.endOfBeer
                    });
                    thisCopy.setState({
                        beerName: response.data.response.name
                    });
                    thisCopy.setState({
                        tapDate: new Date(response.data.response.createdAtTime)
                    });
                    thisCopy.setState({
                        beerUpdatedAt: response.data.response.beerUpdatedAt
                    });
                    thisCopy.setState({
                        temperatureUpdatedAt: response.data.response.temperatureUpdatedAt
                    });
                    thisCopy.setState({beerDoneAtUpdatedAt: response.data.response.beerDoneAtUpdatedAt});
                    thisCopy.setState({rssi: response.data.response.rssi});
                    thisCopy.setState({isStarted: true});
                    thisCopy.timerID = setInterval(() => thisCopy.getValues(), 1000);
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(function (error) {
                thisCopy.setState({noConnection: "block"});
                thisCopy.setState({
                    errorMessage: "Není možné se připojit k backendu. Běží?"
                });
                thisCopy.setState({setBackendAddress: "block"});
                console.log(error);
            });
    }

    handleLoginClick = event => {
        event.preventDefault();
        let thisCopy = this;
        if (this.state.beerName.length > 0) {
            axios
                .post("https://" + thisCopy.state.backendAddress + ":8097/kegs/add", {
                    name: this.state.beerName,
                    scaleId: this.state.scaleId
                })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status === "OK") {
                        thisCopy.setState({noConnection: "none"});
                        thisCopy.setState({setBackendAddress: "none"});
                        thisCopy.setState({maxBeers: response.data.response.volume * 2});
                        thisCopy.setState({
                            remainingBeers: response.data.response.volume * 2
                        });
                        thisCopy.setState({
                            endOfBeer: response.data.response.endOfBeer
                        });
                        thisCopy.setState({
                            temperature: response.data.response.temperature
                        });
                        thisCopy.setState({
                            tapDate: new Date(response.data.response.createdAtTime)
                        });
                        thisCopy.setState({
                            beerUpdatedAt: response.data.response.beerUpdatedAt
                        });
                        thisCopy.setState({
                            temperatureUpdatedAt: response.data.response.temperatureUpdatedAt
                        });
                        thisCopy.setState({beerDoneAtUpdatedAt: response.data.response.beerDoneAtUpdatedAt});
                        thisCopy.setState({rssi: response.data.response.rssi});
                        thisCopy.setState({isStarted: true});
                        thisCopy.timerID = setInterval(() => thisCopy.getValues(), 1000);
                    } else {
                        thisCopy.setState({noConnection: "block"});
                        thisCopy.setState({
                            errorMessage: response.data.error
                        });
                    }
                })
                .catch(function (error) {
                    thisCopy.setState({noConnection: "block"});
                    thisCopy.setState({
                        errorMessage: "Není možné se připojit k backendu. Běží?"
                    });
                    console.log(error);
                });
        }
    };

    handleLogoutClick = event => {
        if (this.state.password === "matejjenejlepsihacker42") {
            clearInterval(this.timerID);
            this.setState({password: ""});
            this.setState({newKeg: "none"});
            this.setState({beerName: ""});
            this.setState({noConnection: "none"});
            this.setState({setBackendAddress: "none"});
            this.setState({isStarted: false});
        } else {
            this.setState({newKeg: "block"});
        }
    };

    handleChange = event => {
        this.setState({beerName: event.target.value});
        this.setState({tapDate: new Date()});
    };

    handleChangePassword = event => {
        this.setState({password: event.target.value});
    };

    getValues() {
        let thisCopy = this;
        axios
            .get("https://" + thisCopy.state.backendAddress + ":8097/kegs/getvalues?useremail=" + thisCopy.state.userEmail)
            .then(response => {
                if (response.data.status === "OK") {
                    this.setState({noConnection: "none"});
                    thisCopy.setState({setBackendAddress: "none"});
                    const tmpRemainingBeers = response.data.response.remainingBeers;
                    this.setState({remainingBeers: tmpRemainingBeers});
                    const tmpTemperature = response.data.response.temperature;
                    this.setState({temperature: tmpTemperature});
                    const tmpEndOfBeer = response.data.response.endOfBeer;
                    this.setState({endOfBeer: tmpEndOfBeer});
                    const tmpBeerUpdatedAt = response.data.response.beerUpdatedAt;
                    this.setState({beerUpdatedAt: tmpBeerUpdatedAt});
                    const tmpTemperatureUpdatedAt = response.data.response.temperatureUpdatedAt;
                    this.setState({temperatureUpdatedAt: tmpTemperatureUpdatedAt});
                    const tmpBeerDoneAtUpdatedAt = response.data.response.beerDoneAtUpdatedAt;
                    this.setState({beerDoneAtUpdatedAt: tmpBeerDoneAtUpdatedAt});
                    const tmpRssi = response.data.response.rssi;
                    this.setState({rssi: tmpRssi});
                } else {
                    thisCopy.setState({noConnection: "block"});
                    thisCopy.setState({
                        errorMessage: response.data.error
                    });
                }
            })
            .catch(function (error) {
                thisCopy.setState({noConnection: "block"});
                thisCopy.setState({
                    errorMessage: "Není možné se připojit k backendu. Běží?"
                });
                console.log(error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        const isStarted = this.state.isStarted;
        const beerName = this.state.beerName;
        const tapDate = this.state.tapDate;
        const remainingBeers = this.state.remainingBeers;
        const temperature = this.state.temperature;
        const endOfBeer = this.state.endOfBeer;
        const maxBeers = this.state.maxBeers;
        const beerUpdatedAt = this.state.beerUpdatedAt;
        const temperatureUpdatedAt = this.state.temperatureUpdatedAt;
        const beerDoneAtUpdatedAt = this.state.beerDoneAtUpdatedAt;
        const rssi = this.state.rssi;
        const iframeSrc = this.state.iframeSrc;
        let button;

        if (isStarted) {
            button = (
                <LogoutButton
                    onClick={this.handleLogoutClick}
                    newKeg={this.state.newKeg}
                    password={this.state.password}
                    handleChangePassword={this.handleChangePassword}
                />
            );
        } else {
            button = <LoginButton onClick={this.handleLoginClick}/>;
        }

        return (
            <div>
                <EntryOrMain
                    beerName={beerName}
                    isStarted={isStarted}
                    noConnection={this.state.noConnection}
                    setBackendAddress={this.state.setBackendAddress}
                    changedIPAddress={this.changedIPAddress}
                    errorMessage={this.state.errorMessage}
                    classes={this.props}
                    handleChange={this.handleChange}
                    onClick={this.handleLoginClick}
                    tapDate={tapDate}
                    remainingBeers={remainingBeers}
                    temperature={temperature}
                    endOfBeer={endOfBeer}
                    beerUpdatedAt={beerUpdatedAt}
                    rssi={rssi}
                    temperatureUpdatedAt={temperatureUpdatedAt}
                    beerDoneAtUpdatedAt={beerDoneAtUpdatedAt}
                    maxBeers={maxBeers}
                    iframeSrc={iframeSrc}
                />
                {button}
            </div>
        );
    }
}

function LoginButton(props) {
    return (
        <form onSubmit={props.onClick}>
            <Button type="submit" color="primary">
                Potvrdit
            </Button>
        </form>
    );
}

function LogoutButton(props) {
    return (
        <div>
            <Button onClick={props.onClick} type="button" color="primary">
                Narazit nové pivo
            </Button>
            <div style={{display: props.newKeg}}>
                Jste si jisti, že už je tento sud dopit? V aktuální demo verzi aplikace
                se k němu nejde vrátit. Pokud ano, zadejte heslo:
                <br/>
                <input
                    type="text"
                    value={props.password}
                    onChange={props.handleChangePassword}
                />
            </div>
        </div>
    );
}

function EntryOrMain(props) {
    const isStarted = props.isStarted;
    if (isStarted) {
        return (
            <MainPage
                classes={props.classes}
                beerName={props.beerName}
                tapDate={props.tapDate}
                remainingBeers={props.remainingBeers}
                temperature={props.temperature}
                endOfBeer={props.endOfBeer}
                beerUpdatedAt={props.beerUpdatedAt}
                temperatureUpdatedAt={props.temperatureUpdatedAt}
                beerDoneAtUpdatedAt={props.beerDoneAtUpdatedAt}
                rssi={props.rssi}
                maxBeers={props.maxBeers}
                noConnection={props.noConnection}
                setBackendAddress={props.setBackendAddress}
                changedIPAddress={props.changedIPAddress}
                errorMessage={props.errorMessage}
                iframeSrc={props.iframeSrc}
            />
        );
    }
    return (
        <EntryPage
            classes={props.classes}
            noConnection={props.noConnection}
            setBackendAddress={props.setBackendAddress}
            changedIPAddress={props.changedIPAddress}
            errorMessage={props.errorMessage}
            beerName={props.beerName}
            handleChange={props.handleChange}
            onClick={props.onClick}
            rssi={props.rssi}
        />
    );
}

function EntryPage(props) {
    const {classes} = props.classes;

    return (
        <div>
            <div style={{display: props.noConnection}}>
                <SnackbarContent
                    message={props.errorMessage}
                    color={"danger"}
                    icon={AddAlert}
                />
            </div>
            <div style={{display: props.setBackendAddress}}>
                Pokud se připojujete z jiného zařízení, zadejte IP adresu serveru:
                <input
                    type="text"
                    value={props.backendAddress}
                    onChange={props.changedIPAddress}
                />
            </div>
            <form onSubmit={props.onClick}>
                <h1>Naražení nového piva</h1>
                <h3>Vítejte v aplikaci Pivalert!</h3>
                <h3>
                    Postavte sud na váhu (bude provedeno počáteční měření) a zadejte název
                    piva:
                </h3>
                <input
                    type="text"
                    value={props.beerName}
                    onChange={props.handleChange}
                />
            </form>
            <br/>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="info">
                                <Icon
                                    style={{display: props.rssi < -80 ? "block" : "none"}}><SignalWifi0BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi < -70 && props.rssi >= -80 ? "block" : "none"}}><SignalWifi1BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi < -60 && props.rssi >= -70 ? "block" : "none"}}><SignalWifi2BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi < -50 && props.rssi >= -60 ? "block" : "none"}}><SignalWifi3BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi >= -50 ? "block" : "none"}}><SignalWifi4BarIcon/></Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Wifi signál (rssi)</p>
                            <h3 className={classes.cardTitle}>{props.rssi} dBm</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <AccessTime/> {new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(props.temperatureUpdatedAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function MainPage(props) {
    const {classes} = props.classes;
    return (
        <div>
            <div style={{display: props.noConnection}}>
                <SnackbarContent
                    message={props.errorMessage}
                    color={"danger"}
                    icon={AddAlert}
                />
            </div>
            <div style={{display: props.setBackendAddress}}>
                Pokud se připojujete z jiného zařízení, zadejte IP adresu serveru:
                <input
                    type="text"
                    value={props.backendAddress}
                    onChange={props.changedIPAddress}
                />
            </div>
            <h1>{props.beerName}</h1>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="info">
                                <Icon>today</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Datum naražení</p>
                            <h3 className={classes.cardTitle}>
                                {new Intl.DateTimeFormat("de-DE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit"
                                }).format(props.tapDate)}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange/>
                                <a href="/admin/historie-piv">
                                    Otevřít historii piv
                                </a>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="warning">
                                <Icon>local_drink</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Zbývá piv</p>
                            <h3 className={classes.cardTitle}>
                                {props.remainingBeers}/{props.maxBeers}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <AccessTime/> {new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(props.beerUpdatedAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="warning">
                                <Icon>local_fire_department</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Teplota</p>
                            <h3 className={classes.cardTitle}>{props.temperature} °C</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <AccessTime/> {new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(props.temperatureUpdatedAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="info">
                                <Icon>dashboard</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Pivo dojde v</p>
                            <h3 className={classes.cardTitle}>{props.endOfBeer}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <AccessTime/> {new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(props.beerDoneAtUpdatedAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="info">
                                <Icon
                                    style={{display: props.rssi < -80 ? "block" : "none"}}><SignalWifi0BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi < -70 && props.rssi >= -80 ? "block" : "none"}}><SignalWifi1BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi < -60 && props.rssi >= -70 ? "block" : "none"}}><SignalWifi2BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi < -50 && props.rssi >= -60 ? "block" : "none"}}><SignalWifi3BarIcon/></Icon>
                                <Icon
                                    style={{display: props.rssi >= -50 ? "block" : "none"}}><SignalWifi4BarIcon/></Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Wifi signál (rssi)</p>
                            <h3 className={classes.cardTitle}>{props.rssi} dBm</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <AccessTime/> {new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(props.temperatureUpdatedAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer xs={12}>
                <GridItem xs={12}>
                    <Card chart>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Historie konzumace</h4>
                            <p className={classes.cardCategory}></p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.iframeSrc
                                }}
                            />
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime/> {new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(props.beerUpdatedAt)}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

Dashboard2.propTypes =
    {
        classes: PropTypes.object.isRequired
    }
;

export default withStyles(dashboardStyle)(Dashboard2);
