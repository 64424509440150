/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    withRouter
} from 'react-router-dom';
import netlifyIdentity from 'netlify-identity-widget';

// core components
import Admin from "layouts/Admin.jsx";
import RTL from "layouts/RTL.jsx";

import "assets/css/material-dashboard-react.css?v=1.7.0";
import logo from "assets/img/logo.png";

window.netlifyIdentity = netlifyIdentity;
// You must run this once before trying to interact with the widget
netlifyIdentity.init();

const hist = createBrowserHistory();

const netlifyAuth = {
    isAuthenticated: false,
    user: null,
    authenticate(callback) {
        this.isAuthenticated = true;
        netlifyIdentity.open();
        netlifyIdentity.on('login', user => {
            this.user = user;
            callback(user);
        });
    },
    signout(callback) {
        this.isAuthenticated = false;
        netlifyIdentity.logout();
        netlifyIdentity.on('logout', () => {
            this.user = null;
            callback();
        });
    }
};

const AuthButton = withRouter(
    ({history}) =>
        netlifyIdentity.currentUser() ? (
            <p align="right">

                <button
                    onClick={() => {
                        netlifyAuth.signout(() => history.push('/'));
                    }}
                >
                    Odhlásit
                </button>
            </p>
        ) : (
            <br></br>
        )
);

function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props =>
                netlifyIdentity.currentUser() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
}

class Login extends React.Component {
    state = {redirectToReferrer: false};

    login = () => {
        netlifyAuth.authenticate(() => {
            this.setState({redirectToReferrer: true});
        });
    };

    render() {
        let {from} = this.props.location.state || {from: {pathname: '/'}};
        let {redirectToReferrer} = this.state;

        if (redirectToReferrer) return <Redirect to={from}/>;

        return (
            <div>
                <a href="https://www.pivalert.eu"><img src={logo} width="70%"></img></a>
                <h1>Vítejte v aplikaci Pivalert. Pro pití piva se, prosím, přihlaste.</h1>
                <button onClick={this.login}>Přihlásit se</button>
                <br></br>
                <p>Pokud už jste přihlášeni, pokračujte kliknutím na logo Pivalertu.</p>
            </div>
        );
    }
}

ReactDOM.render(
    <Router>
        <div>
//            <AuthButton/>
            <Switch>
                <Route path="/admin" component={Admin}/>
                <Route path="/rtl" component={RTL}/>
//                <Route path="/login" component={Login}/>
                <Redirect from="/" to="/admin"/>
            </Switch>
        </div>
    </Router>,
    document.getElementById("root")
);

export default AuthButton;