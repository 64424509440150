/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

function Consumption() {
  return (
    <div
      style={{ height: `80vh` }}
      dangerouslySetInnerHTML={{
        __html:
          '<iframe src="https://' +
          process.env.REACT_APP_COMPUTER_IP_ADDRESS +
          ":3042/d/" +
          process.env.REACT_APP_GRAFANA_ID +
          '/pivalert?orgId=2&refresh=5s&viewPanel=2&fullscreen" width="100%" height="100%"></iframe>'
      }}
    />
  );
}

export default Consumption;
