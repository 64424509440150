/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import DashboardPage2 from "views/Dashboard2/Dashboard2.jsx";
import BeerHistory from "views/BeerHistory/BeerHistory.jsx";
import BeerHistory2 from "views/BeerHistory2/BeerHistory.jsx";
import Consumption from "views/Consumption/Consumption.jsx";
import Temperature from "views/Temperature/Temperature.jsx";
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/pocitadlo",
    name: "Počitadlo",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/historie-piv",
    name: "Historie piv",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: BeerHistory,
    layout: "/admin"
  },
  {
    path: "/historie-konzumace",
    name: "Historie konzumace",
    rtlName: "طباعة",
    icon: "local_drink",
    component: Consumption,
    layout: "/admin"
  },
  {
    path: "/historie-teploty-sudu",
    name: "Historie teploty sudu",
    rtlName: "طباعة",
    icon: "local_fire_department",
    component: Temperature,
    layout: "/admin"
  },
  {
    path: "/pocitadlo2",
    name: "Počitadlo 2",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage2,
    layout: "/admin"
  },
  {
    path: "/historie-piv2",
    name: "Historie piv 2",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: BeerHistory2,
    layout: "/admin"
  }
];

export default dashboardRoutes;
