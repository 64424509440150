/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {/*<div className={classes.left}>*/}
        {/*  <List className={classes.list}>*/}
        {/*    <ListItem className={classes.inlineBlock}>*/}
        {/*      <a href="#home" className={classes.block}>*/}
        {/*        Home*/}
        {/*      </a>*/}
        {/*    </ListItem>*/}
        {/*    <ListItem className={classes.inlineBlock}>*/}
        {/*      <a href="#company" className={classes.block}>*/}
        {/*        Company*/}
        {/*      </a>*/}
        {/*    </ListItem>*/}
        {/*    <ListItem className={classes.inlineBlock}>*/}
        {/*      <a href="#portfolio" className={classes.block}>*/}
        {/*        Portfolio*/}
        {/*      </a>*/}
        {/*    </ListItem>*/}
        {/*    <ListItem className={classes.inlineBlock}>*/}
        {/*      <a href="#blog" className={classes.block}>*/}
        {/*        Blog*/}
        {/*      </a>*/}
        {/*    </ListItem>*/}
        {/*  </List>*/}
        {/*</div>*/}
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a href="https://www.pivalert.eu" target="_blank" className={classes.a}>
              Pivalert, s.r.o.
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
