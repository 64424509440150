import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import axios from "axios";

class BeerHistory extends React.Component {
    state = {
        scaleId: "1599632673095042557",
        backendAddress: process.env.REACT_APP_COMPUTER_IP_ADDRESS,
        tableData: []
    }

    componentDidMount() {
        let thisCopy = this;
        axios
            .get(
                "https://" +
                thisCopy.state.backendAddress +
                ":8097/kegs/getall?scaleid=" + thisCopy.state.scaleId
            )
            .then(response => {
                if (response.data.status === "OK") {
                    thisCopy.setState({
                        tableData: response.data.response.map(obj => [obj.name, obj.volume,
                            new Intl.DateTimeFormat("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                            }).format(obj.createdAtTime), obj.kegWeight])
                    });
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const styles = {
            cardCategoryWhite: {
                "&,& a,& a:hover,& a:focus": {
                    color: "rgba(255,255,255,.62)",
                    margin: "0",
                    fontSize: "14px",
                    marginTop: "0",
                    marginBottom: "0"
                },
                "& a,& a:hover,& a:focus": {
                    color: "#FFFFFF"
                }
            },
            cardTitleWhite: {
                color: "#FFFFFF",
                marginTop: "0px",
                minHeight: "auto",
                fontWeight: "300",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                marginBottom: "3px",
                textDecoration: "none",
                "& small": {
                    color: "#777",
                    fontSize: "65%",
                    fontWeight: "400",
                    lineHeight: "1"
                }
            }
        };
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={styles.cardTitleWhite}>Historie piv</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Název", "Objem", "Naraženo", "Váha sudu (g)"]}
                                tableData={this.state.tableData}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

BeerHistory.propTypes = {
    classes: PropTypes.object
};

export default BeerHistory;